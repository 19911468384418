/* tslint:disable */
/* eslint-disable */
/**
 * CM Catalogue - Catalogue API
 * A service for managing catalogue.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Categories
 */
export interface Categories {
    /**
     * 
     * @type {Array<Category>}
     * @memberof Categories
     */
    'categories': Array<Category>;
}
/**
 * 
 * @export
 * @interface Category
 */
export interface Category {
    /**
     * 
     * @type {number}
     * @memberof Category
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    'file_id': string;
}
/**
 * 
 * @export
 * @interface CategoryPost
 */
export interface CategoryPost {
    /**
     * 
     * @type {string}
     * @memberof CategoryPost
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CategoryPost
     */
    'file_id'?: string;
}
/**
 * 
 * @export
 * @interface CategoryProducts
 */
export interface CategoryProducts {
    /**
     * 
     * @type {Array<Product>}
     * @memberof CategoryProducts
     */
    'data': Array<Product>;
}
/**
 * 
 * @export
 * @interface ModelError
 */
export interface ModelError {
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface Product
 */
export interface Product {
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'description': string;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'price': number;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'status': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Product
     */
    'file_ids': Array<string>;
    /**
     * number use for customize sorting
     * @type {number}
     * @memberof Product
     */
    'position': number;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'preview_id': string;
}
/**
 * 
 * @export
 * @interface ProductPost
 */
export interface ProductPost {
    /**
     * 
     * @type {string}
     * @memberof ProductPost
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProductPost
     */
    'description': string;
    /**
     * 
     * @type {number}
     * @memberof ProductPost
     */
    'price': number;
    /**
     * 
     * @type {string}
     * @memberof ProductPost
     */
    'status': ProductPostStatusEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductPost
     */
    'file_ids'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ProductPost
     */
    'preview_id'?: string;
}

export const ProductPostStatusEnum = {
    InStock: 'in_stock',
    OutOfStock: 'out_of_stock'
} as const;

export type ProductPostStatusEnum = typeof ProductPostStatusEnum[keyof typeof ProductPostStatusEnum];

/**
 * 
 * @export
 * @interface SetProductPositionRequest
 */
export interface SetProductPositionRequest {
    /**
     * 
     * @type {number}
     * @memberof SetProductPositionRequest
     */
    'position'?: number;
}

/**
 * CategoriesApi - axios parameter creator
 * @export
 */
export const CategoriesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a new category
         * @param {CategoryPost} categoryPost 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCategory: async (categoryPost: CategoryPost, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoryPost' is not null or undefined
            assertParamExists('createCategory', 'categoryPost', categoryPost)
            const localVarPath = `/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(categoryPost, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a category
         * @param {number} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCategory: async (categoryId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoryId' is not null or undefined
            assertParamExists('deleteCategory', 'categoryId', categoryId)
            const localVarPath = `/categories/{category_id}`
                .replace(`{${"category_id"}}`, encodeURIComponent(String(categoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all categories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCategories: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get category by id
         * @param {number} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategoryByID: async (categoryId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoryId' is not null or undefined
            assertParamExists('getCategoryByID', 'categoryId', categoryId)
            const localVarPath = `/categories/{category_id}`
                .replace(`{${"category_id"}}`, encodeURIComponent(String(categoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edit the category field
         * @param {number} categoryId 
         * @param {CategoryPost} categoryPost 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCategory: async (categoryId: number, categoryPost: CategoryPost, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoryId' is not null or undefined
            assertParamExists('updateCategory', 'categoryId', categoryId)
            // verify required parameter 'categoryPost' is not null or undefined
            assertParamExists('updateCategory', 'categoryPost', categoryPost)
            const localVarPath = `/categories/{category_id}`
                .replace(`{${"category_id"}}`, encodeURIComponent(String(categoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(categoryPost, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CategoriesApi - functional programming interface
 * @export
 */
export const CategoriesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CategoriesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a new category
         * @param {CategoryPost} categoryPost 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCategory(categoryPost: CategoryPost, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Category>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCategory(categoryPost, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a category
         * @param {number} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCategory(categoryId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCategory(categoryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all categories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllCategories(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Categories>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllCategories(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get category by id
         * @param {number} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCategoryByID(categoryId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Category>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCategoryByID(categoryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Edit the category field
         * @param {number} categoryId 
         * @param {CategoryPost} categoryPost 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCategory(categoryId: number, categoryPost: CategoryPost, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Category>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCategory(categoryId, categoryPost, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CategoriesApi - factory interface
 * @export
 */
export const CategoriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CategoriesApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a new category
         * @param {CategoryPost} categoryPost 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCategory(categoryPost: CategoryPost, options?: any): AxiosPromise<Category> {
            return localVarFp.createCategory(categoryPost, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a category
         * @param {number} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCategory(categoryId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCategory(categoryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all categories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCategories(options?: any): AxiosPromise<Categories> {
            return localVarFp.getAllCategories(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get category by id
         * @param {number} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategoryByID(categoryId: number, options?: any): AxiosPromise<Category> {
            return localVarFp.getCategoryByID(categoryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Edit the category field
         * @param {number} categoryId 
         * @param {CategoryPost} categoryPost 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCategory(categoryId: number, categoryPost: CategoryPost, options?: any): AxiosPromise<Category> {
            return localVarFp.updateCategory(categoryId, categoryPost, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CategoriesApi - object-oriented interface
 * @export
 * @class CategoriesApi
 * @extends {BaseAPI}
 */
export class CategoriesApi extends BaseAPI {
    /**
     * 
     * @summary Create a new category
     * @param {CategoryPost} categoryPost 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApi
     */
    public createCategory(categoryPost: CategoryPost, options?: AxiosRequestConfig) {
        return CategoriesApiFp(this.configuration).createCategory(categoryPost, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a category
     * @param {number} categoryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApi
     */
    public deleteCategory(categoryId: number, options?: AxiosRequestConfig) {
        return CategoriesApiFp(this.configuration).deleteCategory(categoryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all categories
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApi
     */
    public getAllCategories(options?: AxiosRequestConfig) {
        return CategoriesApiFp(this.configuration).getAllCategories(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get category by id
     * @param {number} categoryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApi
     */
    public getCategoryByID(categoryId: number, options?: AxiosRequestConfig) {
        return CategoriesApiFp(this.configuration).getCategoryByID(categoryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Edit the category field
     * @param {number} categoryId 
     * @param {CategoryPost} categoryPost 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApi
     */
    public updateCategory(categoryId: number, categoryPost: CategoryPost, options?: AxiosRequestConfig) {
        return CategoriesApiFp(this.configuration).updateCategory(categoryId, categoryPost, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProductsApi - axios parameter creator
 * @export
 */
export const ProductsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create new product
         * @param {number} categoryId 
         * @param {ProductPost} productPost 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProduct: async (categoryId: number, productPost: ProductPost, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoryId' is not null or undefined
            assertParamExists('createProduct', 'categoryId', categoryId)
            // verify required parameter 'productPost' is not null or undefined
            assertParamExists('createProduct', 'productPost', productPost)
            const localVarPath = `/categories/{category_id}/products`
                .replace(`{${"category_id"}}`, encodeURIComponent(String(categoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productPost, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete product by ID
         * @param {number} categoryId 
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProduct: async (categoryId: number, productId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoryId' is not null or undefined
            assertParamExists('deleteProduct', 'categoryId', categoryId)
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('deleteProduct', 'productId', productId)
            const localVarPath = `/categories/{category_id}/products/{product_id}`
                .replace(`{${"category_id"}}`, encodeURIComponent(String(categoryId)))
                .replace(`{${"product_id"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all products for a category
         * @param {number} categoryId 
         * @param {number} [startPosition] 
         * @param {number} [limit] 
         * @param {string} [filter] 
         * @param {'all' | 'in_stock' | 'out_of_stock'} [statusFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCategoryProducts: async (categoryId: number, startPosition?: number, limit?: number, filter?: string, statusFilter?: 'all' | 'in_stock' | 'out_of_stock', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoryId' is not null or undefined
            assertParamExists('getAllCategoryProducts', 'categoryId', categoryId)
            const localVarPath = `/categories/{category_id}/products`
                .replace(`{${"category_id"}}`, encodeURIComponent(String(categoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startPosition !== undefined) {
                localVarQueryParameter['start_position'] = startPosition;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (statusFilter !== undefined) {
                localVarQueryParameter['statusFilter'] = statusFilter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get product by id
         * @param {number} categoryId 
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductByID: async (categoryId: number, productId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoryId' is not null or undefined
            assertParamExists('getProductByID', 'categoryId', categoryId)
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('getProductByID', 'productId', productId)
            const localVarPath = `/categories/{category_id}/products/{product_id}`
                .replace(`{${"category_id"}}`, encodeURIComponent(String(categoryId)))
                .replace(`{${"product_id"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set product position
         * @param {number} categoryId 
         * @param {number} productId 
         * @param {SetProductPositionRequest} setProductPositionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setProductPosition: async (categoryId: number, productId: number, setProductPositionRequest: SetProductPositionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoryId' is not null or undefined
            assertParamExists('setProductPosition', 'categoryId', categoryId)
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('setProductPosition', 'productId', productId)
            // verify required parameter 'setProductPositionRequest' is not null or undefined
            assertParamExists('setProductPosition', 'setProductPositionRequest', setProductPositionRequest)
            const localVarPath = `/categories/{category_id}/products/{product_id}/set-position`
                .replace(`{${"category_id"}}`, encodeURIComponent(String(categoryId)))
                .replace(`{${"product_id"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setProductPositionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update product by ID
         * @param {number} categoryId 
         * @param {number} productId 
         * @param {ProductPost} productPost 
         * @param {number} [newCategoryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProduct: async (categoryId: number, productId: number, productPost: ProductPost, newCategoryId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoryId' is not null or undefined
            assertParamExists('updateProduct', 'categoryId', categoryId)
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('updateProduct', 'productId', productId)
            // verify required parameter 'productPost' is not null or undefined
            assertParamExists('updateProduct', 'productPost', productPost)
            const localVarPath = `/categories/{category_id}/products/{product_id}`
                .replace(`{${"category_id"}}`, encodeURIComponent(String(categoryId)))
                .replace(`{${"product_id"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (newCategoryId !== undefined) {
                localVarQueryParameter['new_category_id'] = newCategoryId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productPost, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductsApi - functional programming interface
 * @export
 */
export const ProductsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create new product
         * @param {number} categoryId 
         * @param {ProductPost} productPost 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProduct(categoryId: number, productPost: ProductPost, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Product>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProduct(categoryId, productPost, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete product by ID
         * @param {number} categoryId 
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProduct(categoryId: number, productId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProduct(categoryId, productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all products for a category
         * @param {number} categoryId 
         * @param {number} [startPosition] 
         * @param {number} [limit] 
         * @param {string} [filter] 
         * @param {'all' | 'in_stock' | 'out_of_stock'} [statusFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllCategoryProducts(categoryId: number, startPosition?: number, limit?: number, filter?: string, statusFilter?: 'all' | 'in_stock' | 'out_of_stock', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoryProducts>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllCategoryProducts(categoryId, startPosition, limit, filter, statusFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get product by id
         * @param {number} categoryId 
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductByID(categoryId: number, productId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Product>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductByID(categoryId, productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set product position
         * @param {number} categoryId 
         * @param {number} productId 
         * @param {SetProductPositionRequest} setProductPositionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setProductPosition(categoryId: number, productId: number, setProductPositionRequest: SetProductPositionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setProductPosition(categoryId, productId, setProductPositionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update product by ID
         * @param {number} categoryId 
         * @param {number} productId 
         * @param {ProductPost} productPost 
         * @param {number} [newCategoryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProduct(categoryId: number, productId: number, productPost: ProductPost, newCategoryId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Product>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProduct(categoryId, productId, productPost, newCategoryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductsApi - factory interface
 * @export
 */
export const ProductsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create new product
         * @param {number} categoryId 
         * @param {ProductPost} productPost 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProduct(categoryId: number, productPost: ProductPost, options?: any): AxiosPromise<Product> {
            return localVarFp.createProduct(categoryId, productPost, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete product by ID
         * @param {number} categoryId 
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProduct(categoryId: number, productId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteProduct(categoryId, productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all products for a category
         * @param {number} categoryId 
         * @param {number} [startPosition] 
         * @param {number} [limit] 
         * @param {string} [filter] 
         * @param {'all' | 'in_stock' | 'out_of_stock'} [statusFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCategoryProducts(categoryId: number, startPosition?: number, limit?: number, filter?: string, statusFilter?: 'all' | 'in_stock' | 'out_of_stock', options?: any): AxiosPromise<CategoryProducts> {
            return localVarFp.getAllCategoryProducts(categoryId, startPosition, limit, filter, statusFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get product by id
         * @param {number} categoryId 
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductByID(categoryId: number, productId: number, options?: any): AxiosPromise<Product> {
            return localVarFp.getProductByID(categoryId, productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set product position
         * @param {number} categoryId 
         * @param {number} productId 
         * @param {SetProductPositionRequest} setProductPositionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setProductPosition(categoryId: number, productId: number, setProductPositionRequest: SetProductPositionRequest, options?: any): AxiosPromise<void> {
            return localVarFp.setProductPosition(categoryId, productId, setProductPositionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update product by ID
         * @param {number} categoryId 
         * @param {number} productId 
         * @param {ProductPost} productPost 
         * @param {number} [newCategoryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProduct(categoryId: number, productId: number, productPost: ProductPost, newCategoryId?: number, options?: any): AxiosPromise<Product> {
            return localVarFp.updateProduct(categoryId, productId, productPost, newCategoryId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductsApi - object-oriented interface
 * @export
 * @class ProductsApi
 * @extends {BaseAPI}
 */
export class ProductsApi extends BaseAPI {
    /**
     * 
     * @summary Create new product
     * @param {number} categoryId 
     * @param {ProductPost} productPost 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public createProduct(categoryId: number, productPost: ProductPost, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).createProduct(categoryId, productPost, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete product by ID
     * @param {number} categoryId 
     * @param {number} productId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public deleteProduct(categoryId: number, productId: number, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).deleteProduct(categoryId, productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all products for a category
     * @param {number} categoryId 
     * @param {number} [startPosition] 
     * @param {number} [limit] 
     * @param {string} [filter] 
     * @param {'all' | 'in_stock' | 'out_of_stock'} [statusFilter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public getAllCategoryProducts(categoryId: number, startPosition?: number, limit?: number, filter?: string, statusFilter?: 'all' | 'in_stock' | 'out_of_stock', options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).getAllCategoryProducts(categoryId, startPosition, limit, filter, statusFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get product by id
     * @param {number} categoryId 
     * @param {number} productId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public getProductByID(categoryId: number, productId: number, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).getProductByID(categoryId, productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set product position
     * @param {number} categoryId 
     * @param {number} productId 
     * @param {SetProductPositionRequest} setProductPositionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public setProductPosition(categoryId: number, productId: number, setProductPositionRequest: SetProductPositionRequest, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).setProductPosition(categoryId, productId, setProductPositionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update product by ID
     * @param {number} categoryId 
     * @param {number} productId 
     * @param {ProductPost} productPost 
     * @param {number} [newCategoryId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public updateProduct(categoryId: number, productId: number, productPost: ProductPost, newCategoryId?: number, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).updateProduct(categoryId, productId, productPost, newCategoryId, options).then((request) => request(this.axios, this.basePath));
    }
}


