/* tslint:disable */
/* eslint-disable */
/**
 * CM Catalogue - Customer API
 * A service for managing customer.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface CashBookRecord
 */
export interface CashBookRecord {
    /**
     * 
     * @type {number}
     * @memberof CashBookRecord
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof CashBookRecord
     */
    'customer_id': number;
    /**
     * 
     * @type {string}
     * @memberof CashBookRecord
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof CashBookRecord
     */
    'type': string;
    /**
     * 
     * @type {number}
     * @memberof CashBookRecord
     */
    'amount': number;
    /**
     * 
     * @type {string}
     * @memberof CashBookRecord
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof CashBookRecord
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof CashBookRecord
     */
    'updated_at': string;
}
/**
 * 
 * @export
 * @interface CashBookRecordPost
 */
export interface CashBookRecordPost {
    /**
     * 
     * @type {string}
     * @memberof CashBookRecordPost
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof CashBookRecordPost
     */
    'type': CashBookRecordPostTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof CashBookRecordPost
     */
    'amount': number;
    /**
     * 
     * @type {string}
     * @memberof CashBookRecordPost
     */
    'description': string;
}

export const CashBookRecordPostTypeEnum = {
    Credit: 'credit',
    Debit: 'debit'
} as const;

export type CashBookRecordPostTypeEnum = typeof CashBookRecordPostTypeEnum[keyof typeof CashBookRecordPostTypeEnum];

/**
 * 
 * @export
 * @interface CashBookRecords
 */
export interface CashBookRecords {
    /**
     * 
     * @type {Array<CashBookRecord>}
     * @memberof CashBookRecords
     */
    'data': Array<CashBookRecord>;
}
/**
 * 
 * @export
 * @interface Customer
 */
export interface Customer {
    /**
     * 
     * @type {number}
     * @memberof Customer
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'contact': string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'relationship': string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'address': string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'postcode': string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'state': string;
    /**
     * 
     * @type {number}
     * @memberof Customer
     */
    'total_unbilled_amount': number;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'updated_at': string;
}
/**
 * 
 * @export
 * @interface CustomerPost
 */
export interface CustomerPost {
    /**
     * 
     * @type {string}
     * @memberof CustomerPost
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerPost
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerPost
     */
    'contact': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerPost
     */
    'relationship': CustomerPostRelationshipEnum;
    /**
     * 
     * @type {string}
     * @memberof CustomerPost
     */
    'address': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerPost
     */
    'postcode': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerPost
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerPost
     */
    'state': string;
}

export const CustomerPostRelationshipEnum = {
    InCooperation: 'in_cooperation',
    Suspended: 'suspended'
} as const;

export type CustomerPostRelationshipEnum = typeof CustomerPostRelationshipEnum[keyof typeof CustomerPostRelationshipEnum];

/**
 * 
 * @export
 * @interface Customers
 */
export interface Customers {
    /**
     * 
     * @type {Array<Customer>}
     * @memberof Customers
     */
    'data': Array<Customer>;
    /**
     * 
     * @type {Pagination}
     * @memberof Customers
     */
    'pagination': Pagination;
}
/**
 * 
 * @export
 * @interface ModelError
 */
export interface ModelError {
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface Pagination
 */
export interface Pagination {
    /**
     * Total number of items returned.
     * @type {number}
     * @memberof Pagination
     */
    'count': number;
    /**
     * Current page.
     * @type {number}
     * @memberof Pagination
     */
    'page': number;
    /**
     * Total number of items available.
     * @type {number}
     * @memberof Pagination
     */
    'total_count': number;
}

/**
 * CashBookApi - axios parameter creator
 * @export
 */
export const CashBookApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create new cash book record for selected customer
         * @param {number} customerId 
         * @param {CashBookRecordPost} cashBookRecordPost 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCashBookRecord: async (customerId: number, cashBookRecordPost: CashBookRecordPost, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('createCashBookRecord', 'customerId', customerId)
            // verify required parameter 'cashBookRecordPost' is not null or undefined
            assertParamExists('createCashBookRecord', 'cashBookRecordPost', cashBookRecordPost)
            const localVarPath = `/{customer_id}/cash-book`
                .replace(`{${"customer_id"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cashBookRecordPost, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a cash book record from the customer
         * @param {number} customerId 
         * @param {number} cashBookRecordId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCashBookRecord: async (customerId: number, cashBookRecordId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('deleteCashBookRecord', 'customerId', customerId)
            // verify required parameter 'cashBookRecordId' is not null or undefined
            assertParamExists('deleteCashBookRecord', 'cashBookRecordId', cashBookRecordId)
            const localVarPath = `/{customer_id}/cash-book/{cash_book_record_id}`
                .replace(`{${"customer_id"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"cash_book_record_id"}}`, encodeURIComponent(String(cashBookRecordId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List out cash book records for selected customer
         * @param {number} customerId 
         * @param {string} startAt 
         * @param {string} endAt 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCashBookRecords: async (customerId: number, startAt: string, endAt: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getCashBookRecords', 'customerId', customerId)
            // verify required parameter 'startAt' is not null or undefined
            assertParamExists('getCashBookRecords', 'startAt', startAt)
            // verify required parameter 'endAt' is not null or undefined
            assertParamExists('getCashBookRecords', 'endAt', endAt)
            const localVarPath = `/{customer_id}/cash-book`
                .replace(`{${"customer_id"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startAt !== undefined) {
                localVarQueryParameter['start_at'] = (startAt as any instanceof Date) ?
                    (startAt as any).toISOString() :
                    startAt;
            }

            if (endAt !== undefined) {
                localVarQueryParameter['end_at'] = (endAt as any instanceof Date) ?
                    (endAt as any).toISOString() :
                    endAt;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CashBookApi - functional programming interface
 * @export
 */
export const CashBookApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CashBookApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create new cash book record for selected customer
         * @param {number} customerId 
         * @param {CashBookRecordPost} cashBookRecordPost 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCashBookRecord(customerId: number, cashBookRecordPost: CashBookRecordPost, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCashBookRecord(customerId, cashBookRecordPost, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a cash book record from the customer
         * @param {number} customerId 
         * @param {number} cashBookRecordId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCashBookRecord(customerId: number, cashBookRecordId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCashBookRecord(customerId, cashBookRecordId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List out cash book records for selected customer
         * @param {number} customerId 
         * @param {string} startAt 
         * @param {string} endAt 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCashBookRecords(customerId: number, startAt: string, endAt: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CashBookRecords>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCashBookRecords(customerId, startAt, endAt, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CashBookApi - factory interface
 * @export
 */
export const CashBookApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CashBookApiFp(configuration)
    return {
        /**
         * 
         * @summary Create new cash book record for selected customer
         * @param {number} customerId 
         * @param {CashBookRecordPost} cashBookRecordPost 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCashBookRecord(customerId: number, cashBookRecordPost: CashBookRecordPost, options?: any): AxiosPromise<void> {
            return localVarFp.createCashBookRecord(customerId, cashBookRecordPost, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a cash book record from the customer
         * @param {number} customerId 
         * @param {number} cashBookRecordId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCashBookRecord(customerId: number, cashBookRecordId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCashBookRecord(customerId, cashBookRecordId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List out cash book records for selected customer
         * @param {number} customerId 
         * @param {string} startAt 
         * @param {string} endAt 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCashBookRecords(customerId: number, startAt: string, endAt: string, options?: any): AxiosPromise<CashBookRecords> {
            return localVarFp.getCashBookRecords(customerId, startAt, endAt, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CashBookApi - object-oriented interface
 * @export
 * @class CashBookApi
 * @extends {BaseAPI}
 */
export class CashBookApi extends BaseAPI {
    /**
     * 
     * @summary Create new cash book record for selected customer
     * @param {number} customerId 
     * @param {CashBookRecordPost} cashBookRecordPost 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CashBookApi
     */
    public createCashBookRecord(customerId: number, cashBookRecordPost: CashBookRecordPost, options?: AxiosRequestConfig) {
        return CashBookApiFp(this.configuration).createCashBookRecord(customerId, cashBookRecordPost, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a cash book record from the customer
     * @param {number} customerId 
     * @param {number} cashBookRecordId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CashBookApi
     */
    public deleteCashBookRecord(customerId: number, cashBookRecordId: number, options?: AxiosRequestConfig) {
        return CashBookApiFp(this.configuration).deleteCashBookRecord(customerId, cashBookRecordId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List out cash book records for selected customer
     * @param {number} customerId 
     * @param {string} startAt 
     * @param {string} endAt 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CashBookApi
     */
    public getCashBookRecords(customerId: number, startAt: string, endAt: string, options?: AxiosRequestConfig) {
        return CashBookApiFp(this.configuration).getCashBookRecords(customerId, startAt, endAt, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CustomerApi - axios parameter creator
 * @export
 */
export const CustomerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create new customer
         * @param {CustomerPost} customerPost 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCustomerData: async (customerPost: CustomerPost, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerPost' is not null or undefined
            assertParamExists('createCustomerData', 'customerPost', customerPost)
            const localVarPath = `/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customerPost, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete customer data by ID
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCutomerData: async (customerId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('deleteCutomerData', 'customerId', customerId)
            const localVarPath = `/{customer_id}`
                .replace(`{${"customer_id"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all customers
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [filter] 
         * @param {'all' | 'in_cooperation' | 'suspended'} [relationshipFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCustomersData: async (page?: number, limit?: number, filter?: string, relationshipFilter?: 'all' | 'in_cooperation' | 'suspended', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (relationshipFilter !== undefined) {
                localVarQueryParameter['relationshipFilter'] = relationshipFilter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a customer data.
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerDataByID: async (customerId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getCustomerDataByID', 'customerId', customerId)
            const localVarPath = `/{customer_id}`
                .replace(`{${"customer_id"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update customer data
         * @param {number} customerId 
         * @param {CustomerPost} customerPost 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomerData: async (customerId: number, customerPost: CustomerPost, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('updateCustomerData', 'customerId', customerId)
            // verify required parameter 'customerPost' is not null or undefined
            assertParamExists('updateCustomerData', 'customerPost', customerPost)
            const localVarPath = `/{customer_id}`
                .replace(`{${"customer_id"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customerPost, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerApi - functional programming interface
 * @export
 */
export const CustomerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create new customer
         * @param {CustomerPost} customerPost 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCustomerData(customerPost: CustomerPost, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Customer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCustomerData(customerPost, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete customer data by ID
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCutomerData(customerId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCutomerData(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all customers
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [filter] 
         * @param {'all' | 'in_cooperation' | 'suspended'} [relationshipFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllCustomersData(page?: number, limit?: number, filter?: string, relationshipFilter?: 'all' | 'in_cooperation' | 'suspended', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Customers>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllCustomersData(page, limit, filter, relationshipFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a customer data.
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomerDataByID(customerId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Customer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerDataByID(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update customer data
         * @param {number} customerId 
         * @param {CustomerPost} customerPost 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCustomerData(customerId: number, customerPost: CustomerPost, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Customer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCustomerData(customerId, customerPost, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CustomerApi - factory interface
 * @export
 */
export const CustomerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomerApiFp(configuration)
    return {
        /**
         * 
         * @summary Create new customer
         * @param {CustomerPost} customerPost 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCustomerData(customerPost: CustomerPost, options?: any): AxiosPromise<Customer> {
            return localVarFp.createCustomerData(customerPost, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete customer data by ID
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCutomerData(customerId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCutomerData(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all customers
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [filter] 
         * @param {'all' | 'in_cooperation' | 'suspended'} [relationshipFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCustomersData(page?: number, limit?: number, filter?: string, relationshipFilter?: 'all' | 'in_cooperation' | 'suspended', options?: any): AxiosPromise<Customers> {
            return localVarFp.getAllCustomersData(page, limit, filter, relationshipFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a customer data.
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerDataByID(customerId: number, options?: any): AxiosPromise<Customer> {
            return localVarFp.getCustomerDataByID(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update customer data
         * @param {number} customerId 
         * @param {CustomerPost} customerPost 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomerData(customerId: number, customerPost: CustomerPost, options?: any): AxiosPromise<Customer> {
            return localVarFp.updateCustomerData(customerId, customerPost, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomerApi - object-oriented interface
 * @export
 * @class CustomerApi
 * @extends {BaseAPI}
 */
export class CustomerApi extends BaseAPI {
    /**
     * 
     * @summary Create new customer
     * @param {CustomerPost} customerPost 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public createCustomerData(customerPost: CustomerPost, options?: AxiosRequestConfig) {
        return CustomerApiFp(this.configuration).createCustomerData(customerPost, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete customer data by ID
     * @param {number} customerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public deleteCutomerData(customerId: number, options?: AxiosRequestConfig) {
        return CustomerApiFp(this.configuration).deleteCutomerData(customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all customers
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {string} [filter] 
     * @param {'all' | 'in_cooperation' | 'suspended'} [relationshipFilter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public getAllCustomersData(page?: number, limit?: number, filter?: string, relationshipFilter?: 'all' | 'in_cooperation' | 'suspended', options?: AxiosRequestConfig) {
        return CustomerApiFp(this.configuration).getAllCustomersData(page, limit, filter, relationshipFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a customer data.
     * @param {number} customerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public getCustomerDataByID(customerId: number, options?: AxiosRequestConfig) {
        return CustomerApiFp(this.configuration).getCustomerDataByID(customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update customer data
     * @param {number} customerId 
     * @param {CustomerPost} customerPost 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public updateCustomerData(customerId: number, customerPost: CustomerPost, options?: AxiosRequestConfig) {
        return CustomerApiFp(this.configuration).updateCustomerData(customerId, customerPost, options).then((request) => request(this.axios, this.basePath));
    }
}


